import React, { useEffect, useState } from 'react';
import api from '../../../api'; // Adjust the path to your API configuration
import { Table } from 'react-bootstrap';

const Communication = () => {
  const [clients, setClients] = useState([]);

  const fetchClients = async () => {
    try {
      const response = await api.get('/api/allmessage');
      setClients(response.data);
      console.log(response);
    } catch (error) {
      console.error('Error fetching clients:', error);
    }
  };

  useEffect(() => {
    fetchClients();
  }, []);

  return (
    <div className=" card shadow-lg p-4 mb-4">
      <h2 style={{ fontFamily: 'Roboto', fontSize: '34px' }} className="text-center mb-4">
     Communication Report
      </h2>
      <Table striped bordered hover>
        <thead className="bg-success text-white">
          <tr className="bg-success text-white">
            <th className="bg-success text-white">Subject</th>
            <th className="bg-success text-white">Content</th>
            <th className="bg-success text-white">Message Type</th>
            <th className="bg-success text-white">Group Type</th>
            <th className="bg-success text-white">Attachment</th>
          </tr>
        </thead>
        <tbody>
          {clients.map((client, index) => (
            <tr key={index}>
              <td>{client.subject}</td>
              <td>{client.content}</td>
              <td>{client.messageType}</td>
              <td>{client.group}</td>
              <td>
                {client.attachmentUrl && (
                  <img src={client.attachmentUrl} alt="Attachment" style={{ maxWidth: '200px' }} />
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default Communication;
