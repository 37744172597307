import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import 'bootstrap/dist/css/bootstrap.min.css';
import './SmsPortal.css';
import './Group.css';
import api from '../../api';
import Swal from 'sweetalert2';
import { useAuthState } from "react-firebase-hooks/auth";
import auth from "../../firebase.init";
import { Table, Button, Modal, Form } from 'react-bootstrap';
import { toast } from "react-toastify";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import 'flag-icon-css/css/flag-icons.min.css'; 
import axios from 'axios';

// Main App Component
const SmsPortal = () => {
  const [clients, setClients] = useState([]);
  const [clientData, setClientData] = useState({
    name: '',
    mail: '',
    phone: '',
    country: '',
    city: '',
    state: '',
    group: ''
  });
  console.log(clientData)
  const [popupVisible, setPopupVisible] = useState(false);
  const [messageType, setMessageType] = useState('email');
  const [subject, setSubject] = useState('');
  const [messageContent, setMessageContent] = useState('');
  const [attachment, setAttachment] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState(null);
  // const [user] = useAuthState(auth);
  const [user, loading, error] = useAuthState(auth);
  const [editingClient, setEditingClient] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [isFullList, setIsFullList] = useState(false);
  const initialOptions = [
    { value: 'USA', label: 'USA' },
    { value: 'other', label: 'Other country...' }
  ];
  const [countryOptions, setCountryOptions] = useState(initialOptions);


  useEffect(() => {
    if (isFullList) {
      axios.get('https://restcountries.com/v3.1/all') // Example API for fetching countries
        .then(response => {
          const fullCountryOptions = response.data.map(country => ({
            value: country.cca2, // Use Country Code as Value
            label: country.name.common
          }));
          setCountryOptions(fullCountryOptions);
        })
        .catch(error => {
          console.error('Error fetching countries:', error);
        });
    }
  }, [isFullList]);


  
  const groupOptions = [
    { value: 'Family', label: 'Family' },
    { value: 'Friend', label: 'Friend' },
    { value: 'Acquaintance', label: 'Acquaintance' },
    { value: 'Church Member', label: 'Church Member' },
    { value: 'Church Related', label: 'Church Related' },
    { value: 'OTP/OGD', label: 'OTP/OGD' },
    { value: 'My GC School', label: 'My GC School' },
    { value: 'My GC Theater', label: 'My GC Theater' },
    { value: 'TC Student', label: 'TC Student' },
    { value: 'TC Client', label: 'TC Client' },
    { value: 'Supplier', label: 'Supplier' },
    { value: 'Spanish', label: 'Spanish' },
    { value: 'Inner Circle', label: 'Inner Circle' },
  ];
  

  useEffect(() => {
    fetchClients();
}, []);

const fetchClients = async () => {
  if (!user) {
    console.error('User is not authenticated.');
    return;
  }

  try {
    const userEmail = user.email;
    const response = await api.get(`/api/user_clients`, { params: { email: userEmail } });
    setClients(response.data);
    console.log(response);
  } catch (error) {
    console.error('Error fetching clients:', error);
  }
};

useEffect(() => {
  fetchClients();
}, [user]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setClientData(prevState => ({
        ...prevState,
        [name]: value
    }));
};

const handleChangeCountry = (selectedOption) => {
  if (selectedOption.value === 'other') {
    setIsFullList(true);
  } else {
    setClientData({
      ...clientData,
      country: selectedOption.label
    });
  }
};


const handleCheckboxChange = (event) => {
  const value = event.target.value;
  const name = event.target.name;
  let updatedGroups = [];

  if (event.target.checked) {
    updatedGroups = [...selectedGroups, value];
  } else {
    updatedGroups = selectedGroups.filter(selectedValue => selectedValue !== value);
  }

  setSelectedGroups(updatedGroups);
  setClientData({
    ...clientData,
    group: updatedGroups.join(", ") 
  });

  // Optional: If you need to track selected group with label
  setSelectedGroup(updatedGroups.map(v => ({ value: v, label: groupOptions.find(o => o.value === v).label })));
};
const clearForm = () => {
  setClientData({
      name: '',
      mail: '',
      phone: '',
      country: '',
      city: '',
      state: '',
      group: ''
  });
  setSelectedGroups([]); 
  // setCountryOptions([]);
};
const validateForm = () => {
  const { name, mail, phone, country, city, state,group } = clientData;
  if (!name || !mail || !phone || !country || !city || !state || !group) {
    return false;
  }
  return true;
};

const addClient = async () => {
  const userEmail = user.email;

  if (!validateForm()) {
    Swal.fire({
      icon: 'warning',
      title: 'Warning',
      text: 'Please fill out all fields.',
    });
    return;
  }

  try {
    await api.post(`/api/add_client/${userEmail}`, clientData);
  
    fetchClients();
    clearForm();
    Swal.fire({
      icon: 'success',
      title: 'Success',
      text: 'Client added successfully!',
    });
  } catch (error) {
    Swal.fire({
      icon: 'error',
      title: 'Error',
      text: 'Failed to add client.',
    });
  }
};

  const handleFileUpload = (e) => {
    setAttachment(e.target.files[0]);
  };

  const sendMessage = async () => {
    if (!selectedGroups) {
      alert('Please select a group to send the message to.');
      return;
    }
  
    const formData = new FormData();
    formData.append('subject', subject);
    formData.append('content', messageContent);
    formData.append('group', selectedGroups);
    formData.append('messageType', messageType);
    if (messageType === 'sms') {
      formData.append('phoneNumber', phoneNumber);
    } else if (messageType === 'whatsapp') {
      formData.append('phoneNumber', phoneNumber);
    }

    if (attachment) {
      formData.append('attachment', attachment);
    }
    // Log the form data for debugging
    for (let [key, value] of formData.entries()) {
      if (key === 'attachment') {
        console.log(`${key}: ${value.name}, ${value.size} bytes, ${value.type}`);
      } else {
        console.log(`${key}: ${value}`);
      }
    }
    const userEmail = user.email;
    const endpoint = `/api/v1/report/send_message/${userEmail}`;
    
    try {
      await api.post(endpoint, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      console.log('Data sent to server:', formData);
      toast.success('Message sent successfully!', {
        position: "top-center",
      });
      alert('Message sent successfully!');
    } catch (error) {
      console.error('Error sending message', error);
      alert('Error sending message.');
    }
  
    setPopupVisible(false);
  };


  const togglePopup = (type) => {
    setMessageType(type);
    setPopupVisible(true);
  };
  const handleEdit = (client) => {
    setEditingClient(client);
    setShowModal(true);
  };
  const deleteClient = async (clientId) => {
    try {
      await api.delete(`/api/delete_client/${clientId}`);
      setClients(clients.filter(client => client._id !== clientId));
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'Deleted successfully!'
      });
    } catch (error) {
      console.error('Error deleting client:', error);
    }
  };

  const handleSave = async () => {
    try {
      await api.put(`/api/update_client/${editingClient._id}`, editingClient);
      fetchClients();
      setShowModal(false);
      setEditingClient(null);
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'Update successfully!'
      });
    } catch (error) {
      console.error('Error updating client:', error);
    }
  };

  return (
    <div className='container'>
      <div className="">
      <div className="card shadow-lg p-4">
        <h1 style={{ fontFamily: 'Roboto', fontSize: '34px' }} className="text-center mb-4">Client Management</h1>
        <form>
          <div className="row">
            <div className="col-md-6 mb-3">
              <input 
                className="form-control" 
                type="text" 
                name="name" 
                placeholder="Name" 
                value={clientData.name} 
                onChange={handleChange} 
              />
            </div>
            <div className="col-md-6 mb-3">
              <input 
                className="form-control" 
                type="mail" 
                name="mail" 
                placeholder="Email" 
                value={clientData.mail} 
                onChange={handleChange} 
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 mb-3">
              <input 
                className="form-control" 
                type="text" 
                name="phone" 
                placeholder="Phone" 
                value={clientData.phone} 
                onChange={handleChange} 
              />
            </div>
            <div className="col-md-6 mb-3">
              {/* <Select
                options={countryOptions}
                value={clientData.country ? { value: clientData.country, label: clientData.country } : null}
                onChange={(option) => handleSelectChange(option, { name: 'country' })}
                name="country"
                placeholder="Select Country"
              /> */}
              <Select
        options={countryOptions}
        value={clientData.country ? { value: clientData.country, label: clientData.country } : null}
        onChange={handleChangeCountry}
        name="country"
        placeholder="Select Country"
      />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 mb-3">
              <input 
                className="form-control" 
                type="text" 
                name="city" 
                placeholder="City" 
                value={clientData.city} 
                onChange={handleChange} 
              />
            </div>
            <div className="col-md-6 mb-3">
              <input 
                className="form-control" 
                type="text" 
                name="state" 
                placeholder="State" 
                value={clientData.state} 
                onChange={handleChange} 
              />
            </div>
          </div>
          <div className="row">
        <div className="col-md-12 mb-3">
          <label style={{ fontFamily: 'Roboto', fontSize: '34px' }} className='text-left '>Select Group</label>
          <div className="d-flex flex-wrap shadow-lg p-3  bg-body rounded">
            {groupOptions.map((option) => (
              <div key={option.value} className="form-check mr-3 mb-3 mx-1" style={{ minWidth: '115px' }}>
               <input
              className="form-check-input p-2"
              type="checkbox"
              name="group"
              value={option.value}
              checked={selectedGroups.includes(option.value)}
              onChange={handleCheckboxChange}
              id={`groupOption-${option.value}`}
              style={{ accentColor: '#007bff' }} // Custom color for checkboxes
            />
                <label className="form-check-label" htmlFor={`groupOption-${option.value}`}>
                  {option.label}
                </label>
              </div>
            ))}
          </div>
        </div>
      </div>
          <div className="d-grid">
         <button type="button" className="btn btn-primary my-2" onClick={addClient}>Add Client</button> 
          </div>
        </form>
      </div>
    </div>

{/* my client details */}
<div className='row px-2' >
<div className="col-md-12 card shadow-lg p-4 mb-4">
        <h2 style={{ fontFamily: 'Roboto', fontSize: '34px' }} className="text-center mb-4">My Clients Details</h2>
        <div class="row">
        <div class="col">
        <div className="table-container table-responsive">
        <table class="table bg-white rounded shadow-sm table-hover ">
        <thead className="bg-success text-white">
        <tr className="bg-success text-white">
            <th className="bg-success text-white">Name</th>
            <th className="bg-success text-white">Phone</th>
            <th className="bg-success text-white">Email</th>
            <th className="bg-success text-white">Country</th>
            <th className="bg-success text-white">Group</th>
            <th className="bg-success text-white">Edit</th>
            <th className="bg-success text-white">Delete</th>
          </tr>
        </thead>
        <tbody className='my-2'>
          {clients.map((client, index) => (
            <tr key={index}>
              <td>{client.name}</td>
              <td>{client.phone}</td>
              {/* <td>{client.email.substring(0, 6)}</td> */}
              <td>{client.mail}</td>
              <td>{client.country}</td>
              <td>{client.group}</td>
              <td>
                <Button className='phone' variant="warning" onClick={() => handleEdit(client)}>
                  Edit
                </Button>
              </td>
              <td>
                <Button className='phone' variant="danger" onClick={() => deleteClient(client._id)}>
                  Delete
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      </div>
      </div>
      </div>
              {/* Edit Modal */}
      {editingClient && (
        <Modal show={showModal} onHide={() => setShowModal(false)} animation={false}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Client</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="formClientName">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  value={editingClient.name}
                  onChange={(e) => setEditingClient({ ...editingClient, name: e.target.value })}
                />
              </Form.Group>
              <Form.Group controlId="formClientPhone">
                <Form.Label>Phone</Form.Label>
                <Form.Control
                  type="text"
                  value={editingClient.phone}
                  onChange={(e) => setEditingClient({ ...editingClient, phone: e.target.value })}
                />
              </Form.Group>
              <Form.Group controlId="formClientEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  value={editingClient.mail}
                  onChange={(e) => setEditingClient({ ...editingClient, email: e.target.value })}
                />
              </Form.Group>
              <Form.Group controlId="formClientCountry">
                <Form.Label>Country</Form.Label>
                <Form.Control
                  type="text"
                  value={editingClient.country}
                  onChange={(e) => setEditingClient({ ...editingClient, country: e.target.value })}
                />
              </Form.Group>
              <Form.Group controlId="formClientGroup">
                <Form.Label>Group Type</Form.Label>
                <Form.Control
                  type="text"
                  value={editingClient.group}
                  onChange={(e) => setEditingClient({ ...editingClient, group: e.target.value })}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowModal(false)}>
              Close
            </Button>
            <Button variant="primary" onClick={handleSave}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      </div>
</div>

{/* Send Communication */}
      <div className="card shadow-lg p-4">
        <h2 style={{ fontFamily: 'Roboto', fontSize: '34px' }} className="text-center mb-3 fw-bold ">Send Communication</h2>
    <div className="text-center mb-3">
          <button className="btn btn-secondary mx-2" onClick={() => togglePopup('email')}>Send Email</button>
          <button className="btn btn-secondary mx-2" onClick={() => togglePopup('sms')}>Send SMS</button>
          <button className="btn btn-secondary mx-2 my-2" onClick={() => togglePopup('whatsapp')}>Send WhatsApp</button>
        </div>
        </div>
        {popupVisible && (
  <div className="popup">
    <div className="popup-inner">
      <h3>Send {messageType.charAt(0).toUpperCase() + messageType.slice(1)}</h3>
      <div className="form-group">
        <input
          className="form-control"
          type="text"
          placeholder="Subject"
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
        />
      </div>
      {/* {messageType === "email" && (
        <div className="form-group">
          <input
            className="form-control"
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
      )} */}
      <div className="form-group">
        <textarea
          className="form-control"
          placeholder={`Write your ${messageType} content here...`}
          value={messageContent}
          onChange={(e) => setMessageContent(e.target.value)}
        ></textarea>
      </div>
      {/* {(messageType === 'sms' || messageType === 'whatsapp') && (
        <div className="form-group">
          <input
            className="form-control"
            type="tel"
            placeholder="Phone Number"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
          />
        </div>
      )} */}
      {(messageType === 'sms' || messageType === 'whatsapp') && (
        
      <div className="form-group">
       <label htmlFor="phone-input" className="phone-label">Phone Number</label>
        <PhoneInput
          country={'us'}
          value={phoneNumber}
          onChange={(phone) => setPhoneNumber(phone)}
          placeholder="Enter phone number"
          onlyCountries={['us']}
          disableCountryCode={false} 
          disableDropdown={true} 
          masks={{ us: '(...) ...-....' }} 
        />
      </div>
    )} 
      <div className="row">
        <div className="col-md-12 mb-3">
          <label style={{ fontFamily: 'Roboto', fontSize: '34px' }} className='text-left '>Select Group</label>
          <div className="d-flex flex-wrap shadow-lg p-3 bg-body rounded">
            {groupOptions.map((option) => (
              <div key={option.value} className="form-check mr-3 mb-3" style={{ minWidth: '150px' }}>
                <input
              className="form-check-input p-2"
              type="checkbox"
              name="group"
              value={option.value}
              checked={selectedGroups.includes(option.value)}
              onChange={handleCheckboxChange}
              id={`groupOption-${option.value}`}
              style={{ accentColor: '#007bff' }} // Custom color for checkboxes
            />
                <label className="form-check-label" htmlFor={`groupOption-${option.value}`}>
                  {option.label}
                </label>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="form-group">
        <input
          type="file"
          className="form-control-file"
          onChange={handleFileUpload}
        />
      </div>
      <button className="btn btn-primary" onClick={sendMessage}>Send</button>
      <button className="btn btn-secondary" onClick={() => setPopupVisible(false)}>Close</button>
    </div>
  </div>
)}

    </div>
  );
};

export default SmsPortal;
