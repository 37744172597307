// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {getAuth}from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyDPmkbeSqy0kU-nIKSri3zBXxeo1Uzko2Y",
    authDomain: "webportal-9da8d.firebaseapp.com",
    projectId: "webportal-9da8d",
    storageBucket: "webportal-9da8d.appspot.com",
    messagingSenderId: "518889249060",
    appId: "1:518889249060:web:3b9611f9b9f6e3b420fbb1",
    measurementId: "G-DPMWF9VPXC"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app)
export default auth;