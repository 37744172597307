import React, { useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import api from '../../../api'; // Adjust the path to your API configuration
import auth from '../../../firebase.init'; // Adjust the path as necessary
import { Table, Button, Modal, Form } from 'react-bootstrap';
import Swal from 'sweetalert2';

const ReportUpdate = () => {
  const [clients, setClients] = useState([]);
  const [user, loading, error] = useAuthState(auth);
  const [editingClient, setEditingClient] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const fetchClients = async () => {
    if (!user) {
      console.error('User is not authenticated.');
      return;
    }

    try {
      const userEmail = user.email;
      const response = await api.get('/api/clients', {
        params: { email: userEmail }
      });
      setClients(response.data);
      console.log(response);
    } catch (error) {
      console.error('Error fetching clients:', error);
    }
  };

  const deleteClient = async (clientId) => {
    try {
      await api.delete(`/api/delete_client/${clientId}`);
      setClients(clients.filter(client => client._id !== clientId));
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'Deleted successfully!'
      });
    } catch (error) {
      console.error('Error deleting client:', error);
    }
  };

  const handleEdit = (client) => {
    setEditingClient(client);
    setShowModal(true);
  };

  const handleSave = async () => {
    try {
      await api.put(`/api/update_client/${editingClient._id}`, editingClient);
      fetchClients();
      setShowModal(false);
      setEditingClient(null);
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'Update successfully!'
      });
    } catch (error) {
      console.error('Error updating client:', error);
    }
  };

  useEffect(() => {
    if (!loading && user) {
      fetchClients();
    }
  }, [user, loading]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error occurred: {error.message}</div>;
  if (!user) return <div>Please log in to view your clients.</div>;

  return (
    <div className="card shadow-lg p-4 mb-4">
      <h2 style={{ fontFamily: 'Roboto', fontSize: '34px' }} className="text-center mb-4">
        All Clients Edit
      </h2>
      <div class="row">
        <div class="col">
        <div className="table-container table-responsive">
        <table class="table bg-white rounded shadow-sm table-hover ">
        <thead className="bg-success text-white">
          <tr>
            <th>Name</th>
            <th>Phone</th>
            <th>Email</th>
            <th>Country</th>
            <th>Group Type</th>
            <th>Edit Report</th>
            <th>Delete Report</th>
          </tr>
        </thead>
        <tbody>
          {clients.map((client, index) => (
            <tr key={index}>
              <td>{client.name}</td>
              <td>{client.phone}</td>
              <td>{client.mail}</td>
              <td>{client.country}</td>
              <td>{client.group}</td>
              <td>
                <Button className='phone' variant="warning" onClick={() => handleEdit(client)}>
                  Edit
                </Button>
              </td>
              <td>
                <Button className='phone' variant="danger" onClick={() => deleteClient(client._id)}>
                  Delete
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
        </table>
      </div>
      </div>
      </div>

      {/* Edit Modal */}
      {editingClient && (
        <Modal show={showModal} onHide={() => setShowModal(false)} animation={false}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Client</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="formClientName">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  value={editingClient.name}
                  onChange={(e) => setEditingClient({ ...editingClient, name: e.target.value })}
                />
              </Form.Group>
              <Form.Group controlId="formClientPhone">
                <Form.Label>Phone</Form.Label>
                <Form.Control
                  type="text"
                  value={editingClient.phone}
                  onChange={(e) => setEditingClient({ ...editingClient, phone: e.target.value })}
                />
              </Form.Group>
              <Form.Group controlId="formClientEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="text"
                  value={editingClient.mail}
                  onChange={(e) => setEditingClient({ ...editingClient, mail: e.target.value })}
                />
              </Form.Group>
              <Form.Group controlId="formClientCountry">
                <Form.Label>Country</Form.Label>
                <Form.Control
                  type="text"
                  value={editingClient.country}
                  onChange={(e) => setEditingClient({ ...editingClient, country: e.target.value })}
                />
              </Form.Group>
              <Form.Group controlId="formClientGroup">
                <Form.Label>Group Type</Form.Label>
                <Form.Control
                  type="text"
                  value={editingClient.group}
                  onChange={(e) => setEditingClient({ ...editingClient, group: e.target.value })}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowModal(false)}>
              Close
            </Button>
            <Button variant="primary" onClick={handleSave}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};

export default ReportUpdate;
