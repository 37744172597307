import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min'; 
import Home from "./components/Home/Home";
import { Route, Routes } from "react-router-dom";
import Register from './components/Authentication/Register';
import Login from './components/Authentication/Login';
import Navbar from './components/Home/Navbar';
import RequireAuth from './components/Home/hooks/RequireAuth'; // Ensure this path is correct

import Dashboard from './components/Home/dashboard/Dashboard';
import MyProfile from './components/Home/dashboard/MyProfile';
import UserReport from './components/Home/dashboard/UserReport';
import ReportUpdate from './components/Home/dashboard/ReportUpdate';
import Communication from './components/Home/dashboard/Communication';
import ClientUpdate from './components/Home/dashboard/ClientUpdate';
import ManageUsers from './components/Home/dashboard/ManageUsers';
import CreateAdmin from './components/Home/dashboard/CreateAdmin';
import SendCommunication from './components/Home/dashboard/SendCommunication';

function App() {
  return (
    <div className="App">
      <Navbar />
      <Routes>

      <Route path="/signup" element={<Register />} />
        <Route path="/signin" element={<Login />} />
        {/* <Route path="/userdashboard" element={<UserDashboard />} /> */}
        
        <Route path="/" element={
          <RequireAuth>
            <Home />
          </RequireAuth>
        } />

<Route
            path="/dashboard"
            element={

                <Dashboard />
            }
          >
          </Route>
          <Route path="/dashboard/myProfile" element={<MyProfile />} />
          <Route path="/dashboard/userreport" element={<UserReport/>} />
          <Route path="/dashboard/reportupdate" element={<ReportUpdate/>} />
          <Route path="/dashboard/communicationreport" element={<Communication/>} />
          <Route path="/dashboard/dashboarddetail" element={<ClientUpdate/>} />
          <Route path="/dashboard/manageuser" element={<ManageUsers/>} />
          <Route path="/dashboard/manageadmin" element={<CreateAdmin/>} />
          <Route path="/dashboard/sendcommunication" element={<SendCommunication/>} />
      </Routes>
    </div>
  );
}

export default App;
