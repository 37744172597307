import React, { useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import api from '../../../api'; // Adjust the path to your API configuration
import auth from '../../../firebase.init'; // Adjust the path as necessary
import { Table } from 'react-bootstrap';

const UserReport = () => {
  const [clients, setClients] = useState([]);
  const [user, loading, error] = useAuthState(auth);

  const fetchClients = async () => {
    if (!user) {
      console.error('User is not authenticated.');
      return;
    }

    try {
      const userEmail = user.email;
      const response = await api.get('/api/clients', {
        params: { email: userEmail }
      });
      setClients(response.data);
      console.log(response);
    } catch (error) {
      console.error('Error fetching clients:', error);
    }
  };

  useEffect(() => {
    if (!loading && user) {
      fetchClients();
    }
  }, [user, loading]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error occurred: {error.message}</div>;
  if (!user) return <div>Please log in to view your clients.</div>;

  return (
    <div className=" card shadow-lg p-4 mb-4">
      <h2 style={{ fontFamily: 'Roboto', fontSize: '34px' }} className="text-center mb-4">
        All Clients Details
      </h2>
      <Table striped bordered hover>
        <thead className="bg-success text-white">
          <tr className="bg-success text-white">
            <th className="bg-success text-white">Name</th>
            <th className="bg-success text-white">Phone</th>
            <th className="bg-success text-white">Email</th>
            <th className="bg-success text-white">Country</th>
            <th className="bg-success text-white">Group Type</th>
            
          </tr>
        </thead>
        <tbody>
          {clients.map((client, index) => (
            <tr key={index}>
              <td>{client.name}</td>
              <td>{client.phone}</td>
              <td>{client.mail}</td>
              <td>{client.country}</td>
              <td>{client.group}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default UserReport;
