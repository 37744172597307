import React from "react";
import auth from "../../firebase.init";
import { useForm } from "react-hook-form";
import Loading from "../Home/Loading/Loading";
import { useSignInWithEmailAndPassword,  useAuthState, useSignInWithGoogle } from 'react-firebase-hooks/auth';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";


const Login = () => {
    const navigate = useNavigate();
    const { register, formState: { errors }, handleSubmit } = useForm();
    const [user] = useAuthState(auth);
    const [signInWithEmailAndPassword, user2, loading2, error2] =
      useSignInWithEmailAndPassword(auth);
 

    const onSubmit = async (data) => {
        try {
            await signInWithEmailAndPassword(data.email, data.password);
            navigate("/");
        } catch (error) {
            console.error("Authentication error:", error);
        }
    };

    let signInError = null;
    if (loading2) {
        return <Loading />;
      }
      if (error2) {
        console.log(error2);
        toast.error(' Login failed!, please try again', {
          // position: toast.POSITION.TOP_CENTER,
        });
      }
      if (user) {
        toast.success('Successfully logged in!', {
            position: "top-center",
          });
        navigate("/");
      }
    return (
        <div className="container">
        <div className="row justify-content-center ">
            <div className="col-12 col-md-12 col-lg-12">
                <div className="auth-container bg-secondary">
                    <h1 className="h2 mb-3 text-center text-white">Login</h1>
                    <h2 className="text-center mb-4 text-white">Hi, Welcome back 👋</h2>

                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="mb-3">
                            <label htmlFor="email" className="form-label text-white">Email address</label>
                            <input
                                {...register("email", {
                                    required: "Email is Required",
                                    pattern: {
                                        value: /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/,
                                        message: "Provide a valid Email",
                                    },
                                })}
                                id="email"
                                type="email"
                                className="form-control"
                                placeholder="Enter email address"
                            />
                            {errors.email && <div className="text-danger">{errors.email.message}</div>}
                        </div>
                        <div className="mb-3">
                            <label htmlFor="password" className="form-label text-white">Password</label>
                            <input
                                {...register("password", {
                                    required: "Password is Required",
                                    minLength: {
                                        value: 6,
                                        message: "Must be 6 characters or longer",
                                    },
                                })}
                                id="password"
                                type="password"
                                className="form-control"
                                placeholder="Enter your password"
                            />
                            {errors.password && <div className="text-danger text-white">{errors.password.message}</div>}
                        </div>

                        {signInError}

                        <div className="d-grid">
                            <button type="submit" className="btn btn-primary">Login</button>
                        </div>
                    </form>

                    <p className="text-center mt-3 text-white">
                        Not registered yet? <Link to='/signup' className="text-primary text-white">Register now</Link>
                    </p>
                </div>
            </div>
        </div>
    </div>
);
};

export default Login;
