import React, { useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import api from '../../../api'; // Replace with your API utility
import { toast } from "react-toastify";
import { useAuthState } from "react-firebase-hooks/auth";
import auth from "../../../firebase.init";

const SendCommunication = () => {
    const [subject, setSubject] = useState('');
    const [messageContent, setMessageContent] = useState('');
    const [selectedGroups, setSelectedGroups] = useState([]);
    const [messageType, setMessageType] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [attachment, setAttachment] = useState(null);
    const [popupVisible, setPopupVisible] = useState(false);
    const [user, loading, error] = useAuthState(auth);

    const togglePopup = (type) => {
        setMessageType(type);
        setPopupVisible(true);
    };

    const handleCheckboxChange = (event) => {
        const { value } = event.target;
        setSelectedGroups(prev => 
            prev.includes(value) ? prev.filter(group => group !== value) : [...prev, value]
        );
    };

    const handleFileUpload = (event) => {
        setAttachment(event.target.files[0]);
    };
    const groupOptions = [
        { value: 'Family', label: 'Family' },
        { value: 'Friend', label: 'Friend' },
        { value: 'Acquaintance', label: 'Acquaintance' },
        { value: 'Church Member', label: 'Church Member' },
        { value: 'Church Related', label: 'Church Related' },
        { value: 'OTP/OGD', label: 'OTP/OGD' },
        { value: 'My GC School', label: 'My GC School' },
        { value: 'My GC Theater', label: 'My GC Theater' },
        { value: 'TC Student', label: 'TC Student' },
        { value: 'TC Client', label: 'TC Client' },
        { value: 'Supplier', label: 'Supplier' },
        { value: 'Spanish', label: 'Spanish' },
        { value: 'Inner Circle', label: 'Inner Circle' },
      ];
    const sendMessage = async () => {
        if (selectedGroups.length === 0) {
            alert('Please select a group to send the message to.');
            return;
        }

        const formData = new FormData();
        formData.append('subject', subject);
        formData.append('content', messageContent);
        formData.append('group', selectedGroups);
        formData.append('messageType', messageType);

        if (messageType === 'sms' || messageType === 'whatsapp') {
            formData.append('phoneNumber', phoneNumber);
        }

        if (attachment) {
            formData.append('attachment', attachment);
        }

        // Log the form data for debugging
        for (let [key, value] of formData.entries()) {
            if (key === 'attachment') {
                console.log(`${key}: ${value.name}, ${value.size} bytes, ${value.type}`);
            } else {
                console.log(`${key}: ${value}`);
            }
        }

        const userEmail = user.email;
        const endpoint = `/api/v1/report/send_message/${userEmail}`;

        try {
            await api.post(endpoint, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            console.log('Data sent to server:', formData);
            toast.success('Message sent successfully!', {
                position: 'top-center',
            });
            alert('Message sent successfully!');
        } catch (error) {
            console.error('Error sending message', error);
            alert('Error sending message.');
        }

        setPopupVisible(false);
    };

    return (
        <div className="container">
            <div className="card shadow-lg p-4">
                <h2
                    style={{ fontFamily: 'Roboto', fontSize: '34px' }}
                    className="text-center mb-3 fw-bold "
                >
                    Send Communication
                </h2>
                <div className="text-center mb-3">
                    <button className="btn btn-secondary mx-2" onClick={() => togglePopup('email')}>
                        Send Email
                    </button>
                    <button className="btn btn-secondary mx-2" onClick={() => togglePopup('sms')}>
                        Send SMS
                    </button>
                    <button className="btn btn-secondary mx-2 my-2" onClick={() => togglePopup('whatsapp')}>
                        Send WhatsApp
                    </button>
                </div>
            </div>

            {popupVisible && (
  <div className="popup">
    <div className="popup-inner">
      <h3>Send {messageType.charAt(0).toUpperCase() + messageType.slice(1)}</h3>
      <div className="form-group">
        <input
          className="form-control"
          type="text"
          placeholder="Subject"
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
        />
      </div>
      {/* {messageType === "email" && (
        <div className="form-group">
          <input
            className="form-control"
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
      )} */}
      <div className="form-group">
        <textarea
          className="form-control"
          placeholder={`Write your ${messageType} content here...`}
          value={messageContent}
          onChange={(e) => setMessageContent(e.target.value)}
        ></textarea>
      </div>
      {/* {(messageType === 'sms' || messageType === 'whatsapp') && (
        <div className="form-group">
          <input
            className="form-control"
            type="tel"
            placeholder="Phone Number"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
          />
        </div>
      )} */}
      {(messageType === 'sms' || messageType === 'whatsapp') && (
        
      <div className="form-group">
       <label htmlFor="phone-input" className="phone-label">Phone Number</label>
        <PhoneInput
          country={'us'}
          value={phoneNumber}
          onChange={(phone) => setPhoneNumber(phone)}
          placeholder="Enter phone number"
          onlyCountries={['us']}
          disableCountryCode={false} 
          disableDropdown={true} 
          masks={{ us: '(...) ...-....' }} 
        />
      </div>
    )} 
      <div className="row">
        <div className="col-md-12 mb-3">
          <label style={{ fontFamily: 'Roboto', fontSize: '34px' }} className='text-left '>Select Group</label>
          <div className="d-flex flex-wrap shadow-lg p-3 bg-body rounded">
            {groupOptions.map((option) => (
              <div key={option.value} className="form-check mr-3 mb-3" style={{ minWidth: '150px' }}>
                <input
              className="form-check-input p-2"
              type="checkbox"
              name="group"
              value={option.value}
              checked={selectedGroups.includes(option.value)}
              onChange={handleCheckboxChange}
              id={`groupOption-${option.value}`}
              style={{ accentColor: '#007bff' }} // Custom color for checkboxes
            />
                <label className="form-check-label" htmlFor={`groupOption-${option.value}`}>
                  {option.label}
                </label>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="form-group">
        <input
          type="file"
          className="form-control-file"
          onChange={handleFileUpload}
        />
      </div>
      <button className="btn btn-primary" onClick={sendMessage}>Send</button>
      <button className="btn btn-secondary" onClick={() => setPopupVisible(false)}>Close</button>
    </div>
  </div>
)}
                </div>
            );
        };
        
        export default SendCommunication;
        