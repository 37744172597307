import React, { useState, useEffect } from "react";
import axios from "axios";
import { useAuthState } from "react-firebase-hooks/auth";
import auth from "../../../firebase.init";
import { Link, Outlet, useParams } from "react-router-dom";
import api from '../../../api';

import Loading from "../Loading/Loading";

const userRole = {
  user: "user",
  admin: "admin",
  owner: "owner",
};

const Dashboard = () => {
  const [role, setRole] = useState(userRole.user);
  const [user] = useAuthState(auth);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    if (user) {
      
      const userEmail = user.email;
      const fetchUser= async () => {
        try {
          const response = await api.get(`api/v1/user/singleByEmail/${userEmail}`);
          console.log(response)
          if (response.data.success) {
            setRole(response.data.user.role);
          }
        } catch (error) {
          console.error("Error fetching user profile:", error);
        } finally {
          setLoading(false);
        }
      };

      fetchUser();
    }
  }, [user]);

  if (loading) return <Loading />;

  return (
    <div className="container mt-3 bglight h-100">
      <div className="d-flex" id="wrapper">
        {/* Sidebar */}
        <div className="bg-white" id="sidebar-wrapper">

          <div className="list-group list-group-flush my-3">
            {role === userRole.user && (
              <>
                <Link
                  to="dashboarddetail"
                  className="list-group-item list-group-item-action bg-transparent second-text fw-bold"
                >
                  <i className="fas fw-bold  fa-chart-line me-2 "></i>Dashboard
                </Link>
                <Link
                  to="sendcommunication"
                  className="list-group-item list-group-item-action bg-transparent second-text fw-bold"
                >
                  <i className="fas fw-bold  fa-chart-line me-2 "></i>Send Communication
                </Link>
                <Link
                  to="myProfile"
                  class="list-group-item list-group-item-action bg-transparent second-text fw-bold"
                >
                  <i class="fas fa-chart-line me-2"></i>My Profile
                </Link>
              </>
            )}

            {role === userRole.admin && (
              <>

                <Link
                  to="userreport"
                  className="list-group-item list-group-item-action bg-transparent second-text fw-bold"
                >
                  <i className="fas fa-shopping-cart me-2 btn btn-light">Client Report</i>
                </Link>
                <Link
                  to="reportupdate"
                  className="list-group-item list-group-item-action bg-transparent second-text fw-bold"
                >
                  <i className="fas fa-shopping-cart me-2 btn btn-ligh">Client Update</i>
                </Link>
                <Link
                  to="sendcommunication"
                  className="list-group-item list-group-item-action bg-transparent second-text fw-bold"
                >
                  <i className="fas fw-bold  fa-chart-line me-2 ">Send Communication</i>
                </Link>
                <Link
                  to="sendcommunication"
                  className="list-group-item list-group-item-action bg-transparent second-text fw-bold"
                >
                  <i className="fas fa-shopping-cart me-2 btn btn-ligh">Communication Report</i>
                </Link>
                <Link
                  to="myProfile"
                  class="list-group-item list-group-item-action bg-transparent second-text fw-bold"
                >
                  <i class="fas fa-chart-line me-2 btn btn-ligh">My Profile</i>
                </Link>
              </>
            )}
            {role === userRole.owner && (
              <>
 
                <Link
                  to="manageuser"
                  className="list-group-item list-group-item-action bg-transparent second-text fw-bold"
                >
                  <i className="fas fa-shopping-cart me-2 btn btn-light">User Manage</i>
                </Link>
                <Link
                  to="manageadmin"
                  className="list-group-item list-group-item-action bg-transparent second-text fw-bold"
                >
                  <i className="fas fa-shopping-cart me-2 btn btn-light">Admin Setup</i>
                </Link>
                <Link
                  to="userreport"
                  className="list-group-item list-group-item-action bg-transparent second-text fw-bold"
                >
                  <i className="fas fa-shopping-cart me-2 btn btn-light">Client Report</i>
                </Link>
                <Link
                  to="reportupdate"
                  className="list-group-item list-group-item-action bg-transparent second-text fw-bold"
                >
                  <i className="fas fa-shopping-cart me-2 btn btn-ligh">Client Update</i>
                </Link>

                  <Link
                  to="sendcommunication"
                  className="list-group-item list-group-item-action bg-transparent second-text fw-bold"
                >
                  <i className="fas fa-shopping-cart me-2 btn btn-ligh">Send Communication</i>
                </Link>
                <Link
                  to="communicationreport"
                  className="list-group-item list-group-item-action bg-transparent second-text fw-bold"
                >
                  <i className="fas fa-shopping-cart me-2 btn btn-ligh">Communication Report</i>
                </Link>
                <Link
                  to="myProfile"
                  class="list-group-item list-group-item-action bg-transparent second-text fw-bold"
                >
                  <i class="fas fa-chart-line me-2 btn btn-ligh">My Profile</i>
                </Link>
              </>
            )}
            <span
              href="#"
              className="list-group-item list-group-item-action bg-transparent text-danger fw-bold"
            >
              <i className="fas fa-power-off me-2"></i>Logout
            </span>
          </div>
        </div>
        <div id="page-content-wrapper">
          <div className="container-fluid px-4">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
