import React from 'react';
import HomePage from "./HomePage"

const Home = () => {
      return (
            <div>
                <HomePage/>
            </div>
      );
};

export default Home;