import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link, useNavigate } from 'react-router-dom';
import auth from "../../firebase.init";
import { signOut } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";

const Navbar = () => {
const [user] = useAuthState(auth);

const navigate = useNavigate();


const handleSignOut = async () => {
  try {
    await signOut(auth);
    // Redirect to the homepage after signing out
    navigate("/");
  } catch (error) {
    console.error("Error signing out:", error.message);
  }
};
  return (
    <div className='container'>
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <Link className="navbar-brand" to="/">
          <img src="./logo.png" alt="Logo" width="100%" height="30" className='mx-3' />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav ms-auto">
            {user ? (
              <>
                <li className="nav-item ms-3">
                  <Link className="nav-link fw-semibold" to="/dashboard">
                    <span className="text-dark">Dashboard</span>
                  </Link>
                </li>
                <li className="nav-item ms-3">
                  <button className="btn btn-link nav-link" onClick={handleSignOut}>
                    <span className="fw-semibold text-dark">Sign Out</span>
                  </button>
                </li>
              </>
            ) : (
              <>
                <li className="nav-item ms-3">
                  <Link className="nav-link" to="/signin">
                    <span className='btn btn-warning'>Login</span>
                  </Link>
                </li>
                <li className="nav-item ms-3">
                  <Link className="nav-link" to="/signup">
                    <button className="btn btn-info">Sign Up</button>
                  </Link>
                </li>
              </>
            )}
          </ul>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
