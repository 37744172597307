import React, { useState } from "react";
import axios from "axios";
import {
  useAuthState,
  useCreateUserWithEmailAndPassword,
  useSendEmailVerification,
  useSignInWithGoogle,
} from "react-firebase-hooks/auth";
import auth from "../../firebase.init";
import { useLocation, useNavigate } from "react-router-dom";
import Loading from "../Home/Loading/Loading";
import { getRandomFloat } from "../utilitis/getRandomId";
import api from "../../api";

const Register = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [fullName, setFullName] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [user] = useAuthState(auth);

  const location = useLocation();
  const navigate = useNavigate();
  let from = location.state?.from?.pathname || "/";
  const [signInWithGoogle, usergoogle, gloading, error] = useSignInWithGoogle(auth);
  useSendEmailVerification(auth);
  const [createUserWithEmailAndPassword, cuser, loading, hookerror] = useCreateUserWithEmailAndPassword(auth, { sendEmailVerification: false });

  const handleSignup = async (e) => {
    e.preventDefault();
    try {
      if (password !== confirmPassword) {
        console.error("Password and confirm password do not match");
        return;
      } else {
        const createUser = await createUserWithEmailAndPassword(email, password);
        if (createUser) {
          const response = await api.post(
            "/api/v1/user/register",
            {
              email,
              password,
              fullName,
              userId: getRandomFloat(1, 100, 2),
            }
          );
          console.log(response.data);
        }
      }
    } catch (error) {
      console.error("Signup failed", error);
    }
  };

  if (user) {
    navigate(from);
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="container ">
      <div className="card mx-auto" style={{ maxWidth: "5" }}>
        <div className="card-body bg-secondary">
          <h2 className="card-title text-white">Sign Up</h2>
          <form onSubmit={handleSignup}>
            <div className="mb-3">
              <label className="form-label text-white">Full Name:</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Your Full Name"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
                required
              />
            </div>
            <div className="mb-3"> 
              <label className="form-label text-white">Email:</label>
              <input
                type="email"
                className="form-control"
                placeholder="Enter Your Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="mb-3">
              <label className="form-label text-white">Create Password:</label>
              <input
                type="password"
                className="form-control"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <div className="mb-3">
              <label className="form-label text-white">Confirm Password:</label>
              <input
                type="password"
                className="form-control"
                placeholder="Retype Password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              />
            </div>
            <button type="submit" className="btn btn-primary w-100 text-white">
              Sign Up
            </button>
            <div className="form-check mt-3">
              <input type="checkbox" className="form-check-input" />
              <label className="form-check-label text-white">I agree to the terms</label>
            </div>
          </form>
          <div className="mt-3">
            <p className="text-center text-white">
              Already have an account? <a href="/signin">Log in</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
