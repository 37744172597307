import React, { useState } from 'react';
import api from '../../../api';
import { Form, Button, Container, Row, Col, Alert } from 'react-bootstrap';
import { getRandomFloat } from "../../utilitis/getRandomId";
import auth from "../../../firebase.init";
import { useCreateUserWithEmailAndPassword } from "react-firebase-hooks/auth";
import Swal from 'sweetalert2';


const CreateAdmin = () => {
  const [formData, setFormData] = useState({ name: '', email: '', whatsapp: '', userId: getRandomFloat(1, 100, 2), phone: '', password: '', confirmPassword: '' });
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const [createUserWithEmailAndPassword, user, loading, hookError] = useCreateUserWithEmailAndPassword(auth, { sendEmailVerification: false });

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (formData.password !== formData.confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    try {
      // Register user with Firebase Auth
      const createUser = await createUserWithEmailAndPassword(formData.email, formData.password);

      if (createUser) {
        // Create user in backend
        const response = await api.post('/api/v1/user/create-admin', formData);

        Swal.fire({
            icon: 'success',
            title: 'Success',
            text: 'create admin successfully!',
          });
        setError('');
        setFormData({ name: '', email: '', whatsapp: '', phone: '', password: '', confirmPassword: '' });
      }
    } catch (error) {
        Swal.fire({
            icon: 'warning',
            title: 'Warning',
            text: 'Error creating admin.',
          });
      setError((error.response?.data?.error) || 'Error creating admin');
    }
  };

  return (
    <Container>
      <h2 className='my-3'>Create New Admin</h2>
      {message && <Alert variant="success">{message}</Alert>}
      {error && <Alert variant="danger">{error}</Alert>}
      <div className='card shadow-lg p-3 bg-body rounded'>
        <Form onSubmit={handleSubmit}>
          <Row className="mb-3">
            <Col md={6}>
              <Form.Group controlId="formName">
                <Form.Label>Name:</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="formPhone">
                <Form.Label>Phone:</Form.Label>
                <Form.Control
                  type="text"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md={6}>
              <Form.Group controlId="formEmail">
                <Form.Label>Email:</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="formWhatsapp">
                <Form.Label>Whatsapp:</Form.Label>
                <Form.Control
                  type="text"
                  name="whatsapp"
                  value={formData.whatsapp}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md={6}>
              <Form.Group controlId="formPassword">
                <Form.Label>Create Password:</Form.Label>
                <Form.Control
                  type="password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="formConfirmPassword">
                <Form.Label>Confirm Password:</Form.Label>
                <Form.Control
                  type="password"
                  name="confirmPassword"
                  value={formData.confirmPassword}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
            </Col>
          </Row>
          <Button variant="primary" type="submit">Create Admin</Button>
        </Form>
      </div>
    </Container>
  );
};

export default CreateAdmin;
