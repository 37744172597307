import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Table, Button } from 'react-bootstrap'; // Added import for Table and Button components
import api from '../../../api';
import Swal from 'sweetalert2';
const ManageUsers = () => {
  const [users, setUsers] = useState([]);
  const [message, setMessage] = useState('');
  const [remove, setRemove] = useState([]);
  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await api.get('/api/v1/user/allusers', {
      });
      console.log(response)
      setUsers(response.data);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const handleUpdateRole = async (id, role) => {
    const user = users.find((u) => u._id === id);
    if (user.role === 'owner') {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Owners cannot have their role updated........ please action user and admin',
      });
      return;
    }

    try {
      await api.patch(`/api/v1/user/adminpromote/${id}`, { role });
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'User role updated successfully!',
      });
      fetchUsers();
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Failed to update user role.',
      });
    }
  };


  const deleteClient = async (id,role) => {
    const user = users.find((u) => u._id === id);
    if (user.role === 'owner') {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Owners cannot have their role deleted. ....... please action user and admin',
      });
      return;
    }
    try {
      await api.delete(`/api/v1/user/delete_user/${id}`);
      setRemove(remove.filter(user => user._id !== id));
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'Deleted successfully!'
      });
      fetchUsers();
    } catch (error) {
      console.error('Error deleting client:', error);
    }
  };
  return (
    <div className=''>
      <h2 className='my-3'>Manage Users</h2>
      {message && <p>{message}</p>}
      <Table striped bordered hover>
        <thead className="bg-success text-white">
          <tr className="bg-success text-white">
            <th className="bg-success text-white">Name</th>

            <th className="bg-success text-white">Email</th>
            <th className="bg-success text-white">Role</th>
            <th className="bg-success text-white">Actions</th>
            <th className="bg-success text-white">Remove</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user) => (
            <tr key={user._id}>
              <td>{user.fullName}</td>

              <td>{user.email}</td>
              <td>{user.role}</td>
              <td>
                <Button
                  variant="primary"
                  onClick={() => handleUpdateRole(user._id, user.role === 'admin' ? 'user' : 'admin')}
                >
                  {user.role === 'admin' ? 'Demote to User' : 'Promote to Admin'}
                </Button>
              </td>
              <td>
                <Button className='phone' variant="danger" onClick={() => deleteClient(user._id)}>
                  Delete
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default ManageUsers;
